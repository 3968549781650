var path;
var productionURLs = new Array();
var stageURLs = new Array();
var usrURLs = new Array();
var devURLs = new Array();
var stage7URLs = new Array();
var dev1URLs = new Array();
var locales = new Array();
var preprodURLs = new Array();


productionURLs[0] = "https://www.princess.com/";
productionURLs[1] = "https://book.princess.com/";
productionURLs[2] = "http://www.princesscruises.com/";
productionURLs[3] = "https://book.princesscruises.com/";
productionURLs[4] = "https://princess.com/";
productionURLs[5] = "http://princesscruises.com/";
productionURLs[6] = "http://book.princesscruises.com/";
productionURLs[7] = "http://employment.princess.com/";
productionURLs[8] = "http://book.princess.com/";
productionURLs[9] = "https://book.princess.com:443/";
productionURLs[10] = "https://ezbook.princess.com/";
productionURLs[11] = "https://ezbook.hollandamerica.com/";
productionURLs[12] = "https://ezbook.seabourn.com/";
productionURLs[13] = "https://portholesapps.princess.com/";
productionURLs[14] = "https://www.polarres.com/";
productionURLs[15] = "http://www.princess.com/";
productionURLs[16] = "http://princess.com/";
productionURLs[17] = "https://www.onesourcecruises.com/";

stageURLs[0] = "https://stagewww.princess.com/";
stageURLs[1] = "https://stagebook.princess.com/";
stageURLs[2] = "http://stagebook.princess.com/";
stageURLs[3] = "https://stagebook.princess.com:443/";
stageURLs[4] = "https://stageezbook.princess.com/";
stageURLs[5] = "https://stageezbook.hollandamerica.com/";
stageURLs[6] = "https://stageezbook.seabourn.com/";
stageURLs[7] = "https://stageportholesapps.princess.com/";
stageURLs[8] = "https://stagewww.polarres.com/";
stageURLs[9] = "https://stagewww.princess.com/";
stageURLs[10] = "https://stagewww.onesourcecruises.com/";

devURLs[0] = "https://devwww.princess.com/";
devURLs[1] = "https://devbook.princess.com/";
devURLs[2] = "http://devbook.princess.com/";
devURLs[3] = "https://devbook.princess.com:443/";
devURLs[4] = "https://devezbook.princess.com/";
devURLs[5] = "https://devezbook.hollandamerica.com/";
devURLs[6] = "https://devezbook.seabourn.com/";
devURLs[7] = "https://devportholesapps.princess.com/";
devURLs[8] = "https://devwww.polarres.com/";
devURLs[9] = "http://devwww.princess.com/";
devURLs[10] = "https://devwww.onesourcecruises.com/";

stage7URLs[0] = "https://stage7www.princess.com/";
stage7URLs[1] = "https://stage7book.princess.com/";
stage7URLs[2] = "http://stage7book.princess.com/";
stage7URLs[3] = "https://stage7book.princess.com:443/";
stage7URLs[4] = "https://stage7ezbook.princess.com/";
stage7URLs[5] = "https://stage7ezbook.hollandamerica.com/";
stage7URLs[6] = "https://stage7ezbook.seabourn.com/";
stage7URLs[7] = "http://stage7www.princess.com/";

dev1URLs[0] = "https://dev1www.princess.com/";
dev1URLs[1] = "https://dev1book.princess.com/";
dev1URLs[2] = "http://dev1book.princess.com/";
dev1URLs[3] = "https://dev1book.princess.com:443/";
dev1URLs[4] = "https://dev1ezbook.princess.com/";
dev1URLs[5] = "https://dev1ezbook.hollandamerica.com/";
dev1URLs[6] = "https://dev1ezbook.seabourn.com/";
dev1URLs[7] = "http://devwww.princess.com/";
dev1URLs[8] = "https://dev1www.onesourcecruises.com/";

preprodURLs[0] = "https://preprodwww.princess.com/";
preprodURLs[1] = "https://preprodbook.princess.com/";
preprodURLs[2] = "http://preprodbook.princess.com/";
preprodURLs[3] = "https://preprodezbook.princess.com/";
preprodURLs[4] = "https://preprodezbook.hollandamerica.com/";
preprodURLs[5] = "https://preprodezbook.seabourn.com/";
preprodURLs[6] = "https://preprodportholesapps.princess.com/";
preprodURLs[7] = "http://preprodwww.princess.com/";
preprodURLs[8] = "https://preprodwww.onesourcecruises.com/";


locales[0] = "US";
locales[1] = "UK";
locales[2] = "AU";
locales[3] = "IN";
locales[4] = "NON_US";
locales[5] = "CA";
locales[6] = "MX";
locales[6] = "NZ";
locales[7] = "GB";



path = getUrl();

function goToUrl(url, value) {
	if (value == null || value == "") {
			 document.location.href=url;
	}
	else {
		document.location.href=url + value;
	}
}

// open a generic popup window
function openGenericWindow(sURL, sWindowName) {
	subWin = window.open(sURL, sWindowName, 'HEIGHT=600,WIDTH=600,TOP=30,LEFT=70,scrollbars');
}


// Open new window.
function openWindow(url, width, height, toolBar) {
	var options;

	if (width == 0) {
		width = screen.availWidth - 10;
	}

	if (height == 0) {
		varHeight = screen.availWidth - 35;
	}

	if (toolBar == 'yes') {
		options = 'toolbar=yes,location=yes,directories=yes,status=yes,scrollbars=yes,resizable=yes,menubar=yes,width=' + width + ',height=' + height;
	}
	else {
		options = 'toolbar=no,location=no,directories=no,status=no,scrollbars=yes,resizable=no,menubar=no,width=' + width + ',height=' + height;
	}
	window.open(url,"newWindow", options);
}

// prepend a 0 if the number is less than 10
function formatNumber(sNum) {
	sNum = sNum.toString();

	if (parseInt(sNum, 10) < 10) {
		return "0" + sNum;
	}
	return sNum;
}

// trim spaces before and after a string
function trim(sTemp) {
	return sTemp.replace(/^\s+|\s+$/g,'');
}

function isBlank(_str) {
	if(_str == null)  {
		return true;
	} else {
		if (trim(_str).length == 0) {
			return true;
		} else {
			return false;
		}
	}
}

function setCookie(sName, sValue) {

	if(getCookie(sName)==null) {
		if(isValidUsrURL(path)) {
			document.cookie = sName + "=" + escape(sValue) + "; " +
				"path=/; ";
		} else {
			domainname='.princess.com';
			document.cookie = sName + "=" + escape(sValue) + "; " +
				"path=/; domain=" + domainname + "; ";
		}
	}


}

function getCookie(sName) {

	// cookies are separated by semicolons
	var aCookie = document.cookie.split("; ");
	for (var i=0; i < aCookie.length; i++) {
	 	// a name/value pair (a crumb) is separated by an equal sign
		var aCrumb = aCookie[i].split("=");
		if (sName == aCrumb[0]) {
			return unescape(aCrumb[1]);
		}
	}
	// a cookie with the requested name does not exist
	return null;

}

function deleteCookie(sName) {

	document.cookie = sName + "=; expires=Thu, 01-Jan-70 00:00:01 GMT";

}

function repaintScreen() {
	if (isNS4) {
		window.location.reload();
	}
}

function getUrl() {
	var url = DOMPurify.sanitize(window.location.href);
	var pos = 0;
	pos = url.indexOf("/", 10);
	//alert (url.substring(0, pos + 1));
	return url.substring(0, pos + 1);
}

function getPath(url){
	if( url == "" || url == null ){
		url = window.location.href;
	}
	pos = url.indexOf("/", 10);
	return url.substring(pos+1, url.length );
}
function isProductionURL(sURL) {

	for (var i=0; i < productionURLs.length; i++) {
		if (productionURLs[i] == sURL) {
			return true;
		}
	}

	return false;
}

function isStageURL(sURL) {
	for (var i=0; i < stageURLs.length; i++) {
		if (stageURLs[i] == sURL) {
			return true;
		}
	}

	return false;
}


function isDevURL(sURL) {

	for (var i=0; i < devURLs.length; i++) {
		if (devURLs[i] == sURL) {
			return true;
		}
	}

	return false;
}

function isStage7URL(sURL) {

	for (var i=0; i < stage7URLs.length; i++) {
		if (stage7URLs[i] == sURL) {
			return true;
		}
	}

	return false;
}

function isDev1URL(sURL) {

	for (var i=0; i < dev1URLs.length; i++) {
		if (dev1URLs[i] == sURL) {
			return true;
		}
	}

	return false;
}

function isPreprodURL(sURL) {
	for (var i=0; i < preprodURLs.length; i++) {
		if (preprodURLs[i] == sURL) {
			return true;
		}
	}
	return false;
}

function isPrincessURL(sURL){
	var pos = sURL.indexOf("princess.com");
	if(pos > 0){
		return true;
	}
	return false;
}

function isHALURL(sURL){
	var pos = sURL.indexOf("hollandamerica.com");
	if(pos > 0){
		return true;
	}
	return false;
}

function isSeabournURL(sURL){
	var pos = sURL.indexOf("seabourn.com");
	if(pos > 0){
		return true;
	}
	return false;
}

function isValidUsrURL(sURL) {
	if( sURL.indexOf("datl") != -1 ||
		sURL.indexOf("localhost") != -1 ||
		sURL.indexOf("172.29.") != -1 ||
		sURL.indexOf("192.168.") != -1 ) {
		return true;
	} else {
		return false;
	}
}

// puts the desired page in the address bar.
function writeBookUrl(pageNm) {
	if(pageNm.indexOf("http")!=-1) {
		parent.location.href=pageNm;
	} else {
		parent.location.href=getBookUrl(getUrl())+pageNm;
	}
}



// puts the desired page in the address bar.
function writeWWWUrl(pageNm) {
	if(pageNm.indexOf("http")!=-1) {
		parent.location.href=pageNm;
	} else {
		parent.location.href=getWWWUrl(getUrl())+pageNm;
	}
}

function getEzBookUrl(sURL){
	if(isProductionURL(sURL)){
		if(isPrincessURL(sURL)){
			return productionURLs[10];
		}
		if(isHALURL(sURL)){
			return productionURLs[11];
		}
		if(isSeabournURL(sURL)){
			return productionURLs[12];
		}
	}else if(isStageURL(sURL)){
		if(isPrincessURL(sURL)){
			return stageURLs[4];
		}
		if(isHALURL(sURL)){
			return stageURLs[5];
		}
		if(isSeabournURL(sURL)){
			return stageURLs[6];
		}
	}else if(isDevURL(sURL)){
		if(isPrincessURL(sURL)){
			return devURLs[4];
		}
		if(isHALURL(sURL)){
			return devURLs[5];
		}
		if(isSeabournURL(sURL)){
			return devURLs[6];
		}
	}else if(isStage7URL(sURL)){
		if(isPrincessURL(sURL)){
			return stage7URLs[4];
		}
		if(isHALURL(sURL)){
			return stage7URLs[5];
		}
		if(isSeabournURL(sURL)){
			return stage7URLs[6];
		}		
	}else if(isDev1URL(sURL)){
		if(isPrincessURL(sURL)){
			return dev1URLs[4];
		}
		if(isHALURL(sURL)){
			return dev1URLs[5];
		}
		if(isSeabournURL(sURL)){
			return dev1URLs[6];
		}
	}else if(isPreprodURL(sURL)){
		if(isPrincessURL(sURL)){
			return preprodURLs[3];
		}
		if(isHALURL(sURL)){
			return preprodURLs[4];
		}
		if(isSeabournURL(sURL)){
			return preprodURLs[5];
		}
	}else{
		return sURL;
	}
}

function getBookUrl(sURL) {
	if (isProductionURL(sURL)) {
		return productionURLs[1];
	} else if (isStageURL(sURL)){
		return stageURLs[1];
	} else if (isDevURL(sURL)){
		return devURLs[1];
	} else if (isStage7URL(sURL)){
		return stage7URLs[1];		
	} else if (isDev1URL(sURL)){
		return dev1URLs[1];
	} else if (isPreprodURL(sURL)){
		return preprodURLs[1];
	} else {
		return sURL;
	}
}

// For onesourcecruises.com
function getOneSourceUrl(sURL) {
	if (isProductionURL(sURL)) {
		return productionURLs[17];
	} else if (isStageURL(sURL)){
		return stageURLs[10];
	} else if (isDevURL(sURL)){
		return devURLs[10];
	} else if (isDev1URL(sURL)){
		return dev1URLs[8];
	} else if (isPreprodURL(sURL)){
		return preprodURLs[8];
	} else if (isUatURL(sURL)){
		return uatURLs[3];
	} else if (isQaURL(sURL)){
		return qaURLs[3];
	} else if (isStgURL(sURL)){
		return stgURLs[3];
	} else if (isTstURL(sURL)){
		return tstURLs[4];
	} else {
		return sURL;
	}
}

function getUnsecureBookUrl(sURL) {
	if (isProductionURL(sURL)) {
		return productionURLs[8];
	} else if (isStageURL(sURL)){
		return stageURLs[2];
	} else if (isDevURL(sURL)){
		return devURLs[2];
	} else if (isStage7URL(sURL)){
		return stage7URLs[2];		
	} else if (isDev1URL(sURL)){
		return dev1URLs[2];
	} else {
		return sURL;
	}
}

function getWWWUrl(sURL) {
	if (isProductionURL(sURL)) {
		return productionURLs[0];
	} else if(isStageURL(sURL)){
		return stageURLs[0];
	} else if (isDevURL(sURL)){
		return devURLs[0];
	} else if (isStage7URL(sURL)){
		return stage7URLs[0];		
	} else if (isDev1URL(sURL)){
		return dev1URLs[0];
	} else if (isPreprodURL(sURL)){
		return preprodURLs[0];
	} else {
		return sURL;
	}
}

function getPolarVisionUrl(sURL) {
	if (isProductionURL(sURL)) {
		return productionURLs[1];
	} else if (isStageURL(sURL)){
		return stageURLs[1];
	} else if (isDevURL(sURL)){
		return devURLs[8];
	} else if (isStage7URL(sURL)){
		return stageURLs[1];
	} else if (isDev1URL(sURL)){
		return devURLs[8];
	} else if (isPreprodURL(sURL)){
		return preprodURLs[1];
	} else {
		return sURL;
	}
}

// trim leading whitespace char
function leftTrim(s) {
	var objRegExp = /^(\s*)(\b[\w\W]*)$/;

	if(objRegExp.test(s)) {
		//remove leading a whitespace characters
		s = s.replace(objRegExp, '$2');
	}
	return s;
}

// removes pattern from s
function removePattern(s, pattern) {
	// ex. removePattern(' sdfsdj  sdfsd', '\s*') to remove all spaces from the original string

	var objRegExp =  new RegExp(pattern, 'gi' );

	//replace passed pattern matches with blanks
	return s.replace(objRegExp,'');
}


// global function to format 10 digit phone numbers
function formatPhoneNumber(phoneElement, countryCode) {
		var pn = phoneElement.value;
		if(isUSorCanada(countryCode)) {
			if(pn.length == 10) {
				var phone = "(" + pn.substring(0,3) + ")";
				phone += pn.substring(3,6) + "-" + pn.substring(6);
				phoneElement.value = phone;
			}
		}
}


// global function to unformat 10 digit phone numbers
function unformatPhoneNumber(phoneElement, countryCode) {
		var pn = phoneElement.value;

		var whiteSpace = /\D/gi;
		pn = pn.replace(whiteSpace,"");

		// US/CA only
		if(isUSorCanada(countryCode)) {
			if(pn.length > 0 && pn.length != 10) {
				alert("Please ensure your phone number has exactly 10 digits, for example (555)123-4567.  Thank you.");
				phoneElement.focus();
				return false;
			}
		}

		phoneElement.value = pn;
		return true;
}


// Utility to determine if country code is US or Canada
function isUSorCanada(countryCode) {
	var c = countryCode.toUpperCase();

	if(c == "US" || c == "CA") { return true; }

	return false;
}

//trim leading and trailing white space of String
function trimBothEnds(sTemp) {
	// trim leading and trailing spaces for a string

	sTemp = sTemp.toString();
	// alert("The string is = *" + sTemp + "*");
	if (sTemp.length == 0) {
       	// empty string, do nothing
	    return "";
	}
	if (sTemp == null) {
		// null string, return 0 length string
		return "";
	}
	// all blanks, return 0 length string
	var bolAllBlanks = true;
	for (var i=0; i < sTemp.length; i++) {
		if (sTemp.charAt(i) != " ") {
			bolAllBlanks = false;
		}
	}
	if (bolAllBlanks) {
		return "";
	}
	var iNonBlankLeft  = -1;
	var iNonBlankRight = -1;

	for (var i=0; i < sTemp.length; i++) {
		if ((iNonBlankLeft == -1) && (sTemp.charAt(i) != " ")) {
			iNonBlankLeft = i;
			break;
		}
	}

	for (var i=sTemp.length - 1; i >= 0; i--) {
		if ((iNonBlankRight == -1) && (sTemp.charAt(i) != " ")) {
			if (i == sTemp.length - 1) {
				iNonBlankRight = sTemp.length;
			}
			else {
				if (i == 0) {
					iNonBlankRight = 1;
				}
				else {
					iNonBlankRight = i + 1;
				}
			}
		}
	}
	return (sTemp.substring(iNonBlankLeft, iNonBlankRight));
}

// returns basic currency info
function getCurrencyInfo (c) {
	if (c != '' && c != undefined) {
		c = c.toUpperCase();
	}
	var i;
	switch (c) {
		case 'AUD':
			i = {symbol:"AU $", decPt:2, decMk:'.', thouMk:','};
			break;
		case 'CAD':
			i = {symbol:"CA $", decPt:2, decMk:'.', thouMk:','};
			break;
		case 'CNY':
			i = {symbol:"CN\u00A5", decPt:2, decMk:'.', thouMk:','};
			break;
		case 'EUR':
			i = {symbol:"\u20AC", decPt:2, decMk:'.', thouMk:','};
			break;
		case 'GBP':
			i = {symbol:"\u00A3", decPt:2, decMk:'.', thouMk:','};
			break;
		case 'JPY':
			i = {symbol:"\u00A5", decPt:0, decMk:'', thouMk:','};
			break;
		case 'NZD':
			i = {symbol:"NZ $", decPt:2, decMk:'.', thouMk:','};
			break;
		default:
			i = {symbol:"$", decPt:2, decMk:'.', thouMk:','};
	}
	return i;
}

//function for formatting currencies c = decimal pts, d = decimal mark, t = delimiter mark ie: (num).formatCurrency(2, '.', ','); Will default to American standard
Number.prototype.formatCurrency = function(c, d, t){
	var n = this, 
		c = isNaN(c = Math.abs(c)) ? 2 : c, 
		d = d == undefined ? "." : d, 
		t = t == undefined ? "," : t, 
		s = n < 0 ? "-" : "", 
		i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", 
		j = (j = i.length) > 3 ? j % 3 : 0;
	return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};


// ############# Following functions have been moved from old polar_nav_bar js ############
var isLogin = hasLoggedIn();
var srcURL;

if(!isLogin) {
	var srcURL = document.location.href;
	// To capture where the user is coming from, and use it to redirect back
	var locationOfsrcURL = srcURL.indexOf("=http");
	var re=/\%/g;
	if (locationOfsrcURL != -1) {
		srcURL = (srcURL.substr(locationOfsrcURL + 1));
	} else {
		srcURL = escape(srcURL.replace(re, "##"));
	}
}
setCookie("COOKIE_CHECK","YES");

function writeSignInOut() {
	if(isLogin) {
		document.write('<a href="' + getBookUrl(getUrl()) + 'captaincircle/jsp/logout.jsp" class="linksblue" title=" Sign out" >Sign Out </a>');
	} else {
		document.write('<a href="' + getBookUrl(getUrl()) + 'captaincircle/jsp/userLogin.jsp?srcURL=' + srcURL + '" class="linksorange" title=" Sign in to your account" >Sign In </a>');
	}
}



// checking session cookie and browser cookie for login
function hasLoggedIn() {
	return !(isBlank(getCookie("sccn")) || isBlank(getCookie("ei")));
}

function hasLogin() {	
	return hasLoggedIn();
}

function getMixedCaseName(userName) {
	var properName = userName;
	if(userName != "undefined" && userName != "" && userName.length > 2) {
		var re 			= /\s/gi;
		var nameArray 	= userName.split(re);
		properName  = "";
		for(i = 0; i < nameArray.length; i++) {
			var upper	= nameArray[i].substring(0,1).toUpperCase();
			var lower	= "";
			if(nameArray[i].length > 1) {
				lower	= nameArray[i].substring(1).toLowerCase();
			}
			properName += ( (i+1) == nameArray.length ) ? (upper + lower) : (upper + lower) + " ";
		}
		properName = getMixedCaseForDashSeperatedName(properName);
	}
	return properName;
}


// to display the message in IE
function showMesg(str,divIdName) {
	var collection = document.getElementsByTagName('DIV');
	for(iLoop=0;iLoop<collection.length;++iLoop) {
		var idName = collection[iLoop].getAttribute('id');
		if(idName==divIdName) {
			collection[iLoop].innerHTML=str;
		}
	}
}

// This is for ticket (#SAVA-6PXVDF) - The Word after '-'
// in the second name is to be displayed as Mixed case.
function getMixedCaseForDashSeperatedName(name){
	var regEx = /-/g;
	var varArray = name.split(regEx);
	var newName = "";

	if(varArray.length > 1){
		newName = varArray[0];
		for(i = 1; i < varArray.length; i++) {
		   var upper = trim(varArray[i]).substring(0,1).toUpperCase();
		   var lower = trim(varArray[i]).substring(1);
		   newName += "-" + upper + lower;
		}
		return newName;
	}
	return name;
}

var intervalId;
function trackBrochureDownload( spId, elem )
{
	var img = document.createElement("iframe");
	img.src = getBookUrl(getUrl())+'captaincircle/json/trackBrochureDownload.page?brochureId='+spId+"&rn="+(Math.random() * Date.parse(new Date()));
	img.style.cssText="display:none";
	img.id = "trackURL";

	var iframe = document.createElement("iframe");
	iframe.src = elem.id;
	iframe.style.cssText="display:none";
	iframe.id = "brochureURL";
	//document.body.appendChild(iframe);
	document.body.appendChild(img);

	intervalId = setInterval( "cleanUp()",20000);

	window.open(elem.id,'_blank');

	return;

}

function cleanUp()
{

	var remBrochure = document.getElementById("brochureURL");
	if( remBrochure)
		document.body.removeChild(remBrochure);

	var remTrack = document.getElementById("trackURL");
	if( remTrack  )
		document.body.removeChild(remTrack );

	clearInterval(intervalId);
}

function getElementsByName( elm, name )
{
	elms = document.getElementsByName( name );

	if( elms.length == 0 )
	{
		elms = getElementsByName_iefix(elm, name);
		if( elms.length == 0 )
			return null;
	}
	return elms;

}

function localize( countryCode )
{
	var validCountryCode = false;
	var showLocale;

	for( var i = 0; i < locales.length; i++ ){
		if( locales[i] == countryCode ){
			validCountryCode = true;
		}
	}

	if( validCountryCode ){

    switch( countryCode )
    {
      case "US":
      case "CA":
      case "MX":
        countryCode = "US";
        break;

      case "UK":
      case "GB":
        countryCode = "UK";
        break;

      case "AU":
      case "NZ":
        countryCode = "AU";
        break;

      case "IN":
         countryCode = "IN";

      default:
        countryCode = "IN";

    }
		showLocale = countryCode + '_locale';
	}
	else{
		showLocale = 'US_locale';
	}

	var showElms = getElementsByName('div', showLocale);
	var noLocaleElms = getElementsByName('div', 'NO_locale');
	if( showElms.length >0 && noLocaleElms.length > 0 ){
		for( var i= 0; i < showElms.length; i++ )
			showElms[i].style.display="";

		for( var i= 0; i < noLocaleElms.length; i++ )
			noLocaleElms[i].style.display="none";

	}
}

function taRateLoginSuccess() {
	var loginId = getCookie("avi");
	return !isBlank(loginId);
}
function openFooterWindow(url){
  window.open(url, 'ta_rates_footer');
}
function detectBrowser(){
    var browser=navigator.appName;
    var b_version=navigator.appVersion;
    var version=parseFloat(b_version);
    if (browser=="Netscape"){
        return 'NS';
    }else if(browser=="Microsoft Internet Explorer") {
        return 'IE';
    }else{
        return 'UNK';
    }
}
function showFbLike(ref,url,show_face) {
  console.warn('showFbLike is depreciated, remove from page');
}
function listFbLike(){
  console.warn('listFbLike is depreciated, remove from page');
}
